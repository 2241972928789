import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Table as ReactTable } from 'react-bootstrap'
import styles from './Table.module.scss'
import { useSortable, SortControls } from './SortControls'
import ProductRow from './ProductRow'

const Table = ({ survey, isReport = false }) => {
    const [searchTerm, setSearchTerm] = useState('')
    const { requestSort, sortConfig } = useSortable()
    if (!survey || !survey.questions || !survey.products || survey.products.length === 0) return null

    const filteredProducts = survey.products.filter(product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
    )

    const sortedProducts = filteredProducts.sort((a, b) => {
        if (isReport && sortConfig !== null) {
            let aValue = ''
            let bValue = ''
            if (sortConfig.key === 'productName') {
                aValue = a.name
                bValue = b.name
            } else {
                const questionId = sortConfig.key
                aValue = survey.answers?.[a.id]?.[questionId]?.value.toString() || ''
                bValue = survey.answers?.[b.id]?.[questionId]?.value.toString() || ''
            }

            if (aValue === '' && bValue === '') return 0
            if (aValue === '') return 1
            if (bValue === '') return -1
            const direction = sortConfig.direction === 'ascending' ? 1 : -1
            return aValue.localeCompare(bValue) * direction
        }
        return 0
    })

    return (
        <ReactTable className={styles.table} striped bordered >
            <thead>
                <tr>
                    <th>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="text-left">
                                <input
                                    type="text"
                                    placeholder="Produktsuche"
                                    size="24"
                                    onChange={(e) => e.target.value.length > 2 || e.target.value === '' ? setSearchTerm(e.target.value) : null}
                                />
                            </div>
                            {isReport &&
                            <div onClick={isReport ? () => requestSort('productName') : undefined} class="w-100">
                                <SortControls sortOrder={sortConfig?.key === 'productName' ? sortConfig.direction : undefined}/>
                            </div>
                            }
                        </div>
                    </th>
                    {survey.questions.map((question) => {
                        const isSortable = isReport && (question.type === 'text' || question.type === 'select')
                        return (
                            <th scope="col" key={question.id} onClick={isSortable ? () => requestSort(question.id) : undefined}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="text-left">{question.text}</div>
                                    {isSortable &&
                                        <SortControls sortOrder={sortConfig?.key === question.id ? sortConfig.direction : undefined}/>
                                    }
                                </div>
                            </th>
                        )
                    })}
                </tr>
            </thead>
            <tbody>
                {sortedProducts.map((product, index) => (
                    <ProductRow
                        key={index}
                        product={product}
                        survey={survey}
                        isReport={isReport}
                    />
                ))}
            </tbody>
        </ReactTable>
    )
}

Table.propTypes = {
    survey: PropTypes.object,
    isReport: PropTypes.bool
}

export default Table
