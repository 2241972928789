import './App.scss'
import {
    BrowserRouter,
    Routes,
    Route
} from 'react-router-dom'
import Surveys from './pages/Surveys'
import React from 'react'
import StoreProvider from './store'
import Report from './pages/Report'
import 'bootstrap/dist/js/bootstrap.min'

function App () {
    return (
        <StoreProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/surveys/:id" element={<Surveys/>}/>
                    <Route path="/reports/:id" element={<Report/>}/>
                    <Route path="/report/" element={<Report/>}/>
                    <Route path="/surveys/" element={null}/>
                    <Route path="/" element={null}/>
                </Routes>
            </BrowserRouter>
        </StoreProvider>
    )
}

export default App
