import React, { useState } from 'react'
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

export const SortControls = React.memo(({ sortOrder }) => {
    const isAscending = sortOrder === 'ascending'
    const isDescending = sortOrder === 'descending'

    return (
        <div className="d-flex flex-column align-items-end ms-1">
            <FontAwesomeIcon
                icon={faSortUp}
                transform={`down-7 ${isAscending ? 'grow-6' : ''}`}
            />
            <FontAwesomeIcon
                icon={faSortDown}
                transform={`up-7 ${isDescending ? 'grow-6' : ''}`}
            />
        </div>
    )
})

SortControls.propTypes = {
    sortOrder: PropTypes.string
}

export const useSortable = () => {
    const [sortConfig, setSortConfig] = useState({ key: 'productName', direction: 'ascending' })

    const requestSort = (key) => {
        let direction = 'ascending'
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending'
        }
        setSortConfig({ key, direction })
    }

    return {
        requestSort,
        sortConfig
    }
}
