import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Table from '../components/Table'
import api from '../services/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudArrowDown } from '@fortawesome/free-solid-svg-icons/faCloudArrowDown'
import { Button } from 'react-bootstrap'

const Report = () => {
    const { id } = useParams()
    const [survey, setSurvey] = useState({})

    useEffect(() => {
        api.getReport(id).then(survey => {
            setSurvey(survey)
        })
    }, [id])

    return (
        <>
            {survey?.error
                ? (
                    <div>
                        <h1>Preview Error</h1>
                        <p>{survey.error}</p>
                    </div>
                )
                : (
                    <Table survey={survey} isReport/>
                )}
            <Button href={`${id ? `/reports/${id}/download/` : '/report/download/'}`} target="_blank">
                Herunterladen <FontAwesomeIcon icon={faCloudArrowDown} />
            </Button>
        </>
    )
}

export default Report
