import React from 'react'
import PropTypes from 'prop-types'
import Field from './Field/Index'
import Hierarchy from './Hierarchy'
import ReportField from './ReportField'
import styles from './Table.module.scss'

const ProductRow = React.memo(({ product, survey, isReport }) => {
    return (
        <tr>
            <th scope="row">
                {!isReport && <Hierarchy parents={product?.parent_names || []}></Hierarchy>}
                <p>{product.name}</p>
            </th>
            {survey.questions.map((question, index) => {
                const highlightCell = isReport && survey.answers?.[product.id]?.[question.id]?.from_earlier_survey === false
                return (
                    <td key={question.id} className={highlightCell ? styles.highlight : undefined}>
                        {isReport
                            ? (
                                <ReportField
                                    answer={survey.answers?.[product.id]?.[question.id]}
                                />
                            )
                            : <Field surveyId={survey.id} product={product} question={question}/>}
                    </td>
                )
            })}
        </tr>
    )
})

ProductRow.propTypes = {
    product: PropTypes.object.isRequired,
    survey: PropTypes.object.isRequired,
    isReport: PropTypes.bool.isRequired
}

export default ProductRow
